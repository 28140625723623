import './default.css';

import { Box } from '@chakra-ui/react';
import { RouteComponentProps } from '@reach/router';
import { graphql } from 'gatsby';
import React from 'react';

import { Layout } from '../components/layout/Layout';

export default function AdatvedelemPage({
  data,
}: RouteComponentProps): JSX.Element {
  return (
    <Layout>
      <Box className="default">
        <Box dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      </Box>
    </Layout>
  );
}

export const query = graphql`
  query {
    markdownRemark(fileAbsolutePath: { regex: "/privacystatement/" }) {
      id
      html
    }
  }
`;
